<template>
  <Disclosure as="nav" class="bg-white border-b border-gray-200 sticky top-0 z-50" v-slot="{ open }">
    <div class="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
      <div class="flex justify-between h-16">

        <div class="flex">
          <!-- icon Home -->
          <div class="flex-shrink-0 flex items-center">
            <NuxtLink to="/">
              <img v-if="$store.display.breakpoint.sm" class="block h-8 w-auto"
                src="~assets/images/mini-logo-edvenn.svg" alt="Edvenn logo" />
              <img v-else class="block h-8 w-auto" src="~assets/images/logo-edvenn.svg" alt="Edvenn logo" />
            </NuxtLink>
          </div>
          <!-- menu item -->
          <div class="hidden sm:-my-px sm:ml-6 sm:flex sm:space-x-8">

            <!-- Home -->
            <NuxtLink v-if="!$store.account.isInternal" to="/" custom v-slot="{ href, navigate, isActive }">
              <a :href="href" @click="navigate"
                :class="[isActive ? 'border-blue text-gray-900' : 'border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700']"
                class="inline-flex items-center px-1 pt-1 border-b-2 text-sm font-medium">Accueil</a>
            </NuxtLink>

            <!-- trainings -->
            <Menu v-if="trainings.length" as="div"
              :class="[$route.path.startsWith('/training') ? 'border-blue text-gray-900' : 'border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700']"
              class="inline-flex items-center px-1 pt-1 border-b-2 text-sm font-medium">
              <div class="relative z-20 h-full w-full">
                <MenuButton class="h-full w-full">
                  <span>Mes formations</span>
                  <span v-if="$store.account.trainingIdWaiting"
                    class="h-2 w-2 ml-2 inline-block align-middle rounded-full bg-orange-600"></span>
                </MenuButton>
                <transition enter-active-class="transition ease-out duration-200"
                  enter-from-class="transform opacity-0 scale-95" enter-to-class="transform opacity-100 scale-100"
                  leave-active-class="transition ease-in duration-75" leave-from-class="transform opacity-100 scale-100"
                  leave-to-class="transform opacity-0 scale-95">
                  <MenuItems style="width: 200%;"
                    class="origin-top-right absolute mt-2 rounded-md shadow-lg py-1 bg-white focus:outline-none">
                    <MenuItem as="span" v-for="item in trainings" :key="item.ref" v-slot="{ active }">
                    <NuxtLink as="a" :to="item.href" active-class="font-bold"
                      :class="[active ? 'bg-gray-100' : '', 'block px-4 py-2 text-sm text-gray-700']">
                      {{ item.name }}
                      <span v-if="item.waitingAttendance"
                        class="h-2 w-2 inline-block align-middle rounded-full bg-orange-600"></span>
                    </NuxtLink>
                    </MenuItem>
                  </MenuItems>
                </transition>
              </div>
            </Menu>

            <!-- admin -->
            <template v-for="nav in adminNavigation">
              <Menu as="div"
                :class="[$route.fullPath.startsWith(nav.href + '/') ? 'border-blue text-gray-900' : 'border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700']"
                class="inline-flex items-center px-1 pt-1 border-b-2 text-sm font-medium">
                <div class="relative z-20 h-full w-full">
                  <MenuButton class="h-full">
                    {{ nav.name }}
                  </MenuButton>
                  <transition enter-active-class="transition ease-out duration-200"
                    enter-from-class="transform opacity-0 scale-95" enter-to-class="transform opacity-100 scale-100"
                    leave-active-class="transition ease-in duration-75"
                    leave-from-class="transform opacity-100 scale-100" leave-to-class="transform opacity-0 scale-95">
                    <MenuItems style="width: 300%;"
                      class="origin-top-right absolute mt-2 rounded-md shadow-lg py-1 bg-white focus:outline-none">
                      <MenuItem as="span" v-for="item in nav.items" :key="item.href" v-slot="{ active }">
                      <NuxtLink as="a" :to="item.href" active-class="font-bold"
                        :class="[active ? 'bg-gray-100' : '', 'block px-4 py-2 text-sm text-gray-700']">
                        {{ item.name }}
                      </NuxtLink>
                      </MenuItem>
                    </MenuItems>
                  </transition>
                </div>
              </Menu>
            </template>
          </div>
        </div>

        <div class="hidden sm:ml-6 sm:flex sm:items-center">

          <!-- waiting attendance -->
          <NuxtLink v-if="$store.account.trainingIdWaiting"
            :to="{ name: 'training-id-attendance', params: { id: $store.account.trainingIdWaiting } }" custom
            v-slot="{ href, navigate }">
            <a :href="href" @click="navigate" title="émargement en attente"
              class="inline-flex items-center px-1 pt-1 border-b-2 text-sm font-medium border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700">
              <AcademicCapIcon class="h-5 w-5 text-orange-600" />
            </a>
          </NuxtLink>

          <!-- Profile dropdown -->
          <Menu as="div" class="ml-3 relative">
            <MenuButton
              class="max-w-xs bg-white text-gray-400 flex items-center text-sm rounded-full focus:outline-none focus:ring-1 focus:ring-offset-1 focus:ring-blue p-0.5">
              <span class="sr-only">Open user menu</span>
              <ContactIcon />
              <!-- <img class="h-8 w-8 rounded-full" :src="`/file/user/picture/${$store.account.user.id}?token=${$store.account.token.access}`" alt="" /> -->
            </MenuButton>
            <transition enter-active-class="transition ease-out duration-200"
              enter-from-class="transform opacity-0 scale-95" enter-to-class="transform opacity-100 scale-100"
              leave-active-class="transition ease-in duration-75" leave-from-class="transform opacity-100 scale-100"
              leave-to-class="transform opacity-0 scale-95">
              <MenuItems
                class="z-40 origin-top-right absolute right-0 mt-2 w-48 rounded-md shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
                <MenuItem v-for="item in myAccount" :key="item.name" v-slot="{ active }">
                <div>
                  <NuxtLink v-if="item.href" :to="item.href"
                    class="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-50">
                    {{ item.name }}
                  </NuxtLink>
                  <a v-else-if="item.click" @click="item.click" target="_blank"
                    class="block cursor-pointer px-4 py-2 text-sm text-gray-700 hover:bg-gray-50">
                    {{ item.name }}
                  </a>
                  <span v-else :class="[item.class || '', 'block px-4 py-2 text-sm text-gray-700']">
                    {{ item.name }}
                  </span>
                </div>
                </MenuItem>
              </MenuItems>
            </transition>
          </Menu>
        </div>

        <!-- Mobile menu button -->
        <div class="-mr-2 flex items-center sm:hidden">
          <NuxtLink v-if="$store.account.trainingIdWaiting"
            :to="{ name: 'training-id-attendance', params: { id: $store.account.trainingIdWaiting } }" custom
            v-slot="{ href, navigate }">
            <a :href="href" @click="navigate" title="émargement en attente"
              class="inline-flex items-center px-1 pt-1 border-b-2 text-sm font-medium border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700">
              <AcademicCapIcon class="h-5 w-5 text-orange-600" />
            </a>
          </NuxtLink>
          <DisclosureButton
            class="bg-white inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue">
            <span class="sr-only">Open main menu</span>
            <Bars3Icon v-if="!open" class="block h-6 w-6" aria-hidden="true" />
            <XMarkIcon v-else class="block h-6 w-6" aria-hidden="true" />
          </DisclosureButton>
        </div>

      </div>
    </div>

    <DisclosurePanel class="sm:hidden">
      <div class="pt-2 pb-3 space-y-1">
        <NuxtLink to="/" custom v-slot="{ href, navigate, isExactActive }">
          <DisclosureButton as="a" :href="href"
            :class="[isExactActive ? 'bg-blue-50 border-blue text-blue-700' : 'border-transparent text-gray-600 hover:bg-gray-50 hover:border-gray-300 hover:text-gray-800', 'block pl-3 pr-4 py-2 border-l-4 text-base font-medium']"
            :aria-current="isExactActive ? 'page' : undefined" @click="navigate">
            Accueil
          </DisclosureButton>
        </NuxtLink>
        <NuxtLink v-for="item in trainings" :key="item.name" :to="item.href" custom
          v-slot="{ href, navigate, isExactActive }">
          <DisclosureButton as="a" :href="href"
            :class="[isExactActive ? 'bg-blue-50 border-blue text-blue-700' : 'border-transparent text-gray-600 hover:bg-gray-50 hover:border-gray-300 hover:text-gray-800', 'block pl-3 pr-4 py-2 border-l-4 text-base font-medium']"
            :aria-current="isExactActive ? 'page' : undefined" @click="navigate">
            {{ item.name }}
          </DisclosureButton>
        </NuxtLink>
      </div>
      <div class="pt-4 pb-3 border-t border-gray-200">
        <div class="flex items-center px-4">
          <div class="flex-shrink-0">
            <ContactIcon />
          </div>
          <div class="ml-3">
            <div class="text-base font-medium text-gray-800">
              {{ $store.account.user.firstname }} {{ $store.account.user.lastname }}
            </div>
            <div class="text-sm font-medium text-gray-500">{{ $store.account.user.email }}</div>
          </div>
        </div>
        <div class="mt-3 space-y-1">
          <template v-for="item in myAccount" :key="item.name">

            <NuxtLink v-if="item.href" :to="item.href" custom v-slot="{ href, navigate, isExactActive }">
              <DisclosureButton as="a" :href="href"
                :class="[isExactActive ? 'bg-blue-50 border-blue text-blue-700' : 'border-transparent text-gray-600 hover:bg-gray-50 hover:border-gray-300 hover:text-gray-800', 'block pl-3 pr-4 py-2 border-l-4 text-base font-medium']"
                :aria-current="isExactActive ? 'page' : undefined" @click="navigate">
                {{ item.name }}
              </DisclosureButton>
            </NuxtLink>
            <DisclosureButton as="a" v-else-if="item.click"
              class="cursor-pointer border-transparent text-gray-600 hover:bg-gray-50 hover:border-gray-300 hover:text-gray-800 block pl-3 pr-4 py-2 border-l-4 text-base font-medium">
              <a @click="item.click" target="_blank">
                {{ item.name }}
              </a>
            </DisclosureButton>
            <span v-else :class="item.class">
              {{ item.name }}
            </span>
          </template>
        </div>
      </div>
    </DisclosurePanel>
  </Disclosure>
</template>


<script lang="ts">
import { defineComponent, computed } from 'vue'
import { RouteLocation } from 'vue-router'
import { Disclosure, DisclosureButton, DisclosurePanel, Menu, MenuButton, MenuItem, MenuItems } from '@headlessui/vue'
import { Bars3Icon, XMarkIcon } from '@heroicons/vue/24/outline'
import { AcademicCapIcon } from '@heroicons/vue/24/solid'

import { mailtoSupport } from '@/util'
import { adminRoutes } from '@/middleware/public.global'

type TrainingLink = {
  name: string;
  ref: string;
  waitingAttendance?: boolean;
  href: RouteLocation
}

export default defineComponent({
  components: {
    Disclosure, DisclosureButton, DisclosurePanel, Menu, MenuButton, MenuItem, MenuItems,
    Bars3Icon, XMarkIcon, AcademicCapIcon
  },

  setup() {

    const { $store, $router } = useNuxtApp()

    const trainings = ref<TrainingLink[]>([])

    watch([() => $store.account.trainingsLoaded, () => $store.account.trainingsFullyLoaded, $router.currentRoute], () => {
      const currentRouteName = $router.currentRoute.value.name.toString()
      trainings.value = $store.account.allTrainings.map(p => ({
        name: p.name!,
        ref: p.reference!,
        waitingAttendance: p.waitingAttendance,
        href: {
          name: currentRouteName.startsWith('training-id') ? currentRouteName : 'training-id',
          params: { id: p.id }
        } as unknown as RouteLocation
      }))
    })

    return {
      myAccount: computed(() => [
        { name: `${$store.account.user?.firstname} ${$store.account.user?.lastname}`, class: "border-b-2 hidden sm:block" },
        { name: 'Mon compte', href: '/account' },
        { name: 'Signaler un problème', click: () => window.open(mailtoSupport($store.account?.user?.email), '_blank') },
        { name: 'Déconnexion', click: () => $store.account.logout() }
      ]),

      trainings,

      adminNavigation: computed(() => adminRoutes(...$store.account.token.accessDecoded.rights))
    }
  }
})

</script>